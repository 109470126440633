<template>
  <v-row justify="center">
    <v-col cols="8">
      <v-card elevation="8" class="mx-auto my-5 popup-background">
        <v-card-text class="py-5 px-10">
          <div class="text-h3 primary--text text-center mb-5">
            ชำระเงิน
          </div>
          <v-row justify="center" class="text-h6">
            <v-col cols="4" class=" text-left">
              <div>ราคารวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ส่วนลดรวม</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ data.discount | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>มูลค่าแพ็กเกจคงเหลือ</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>disable</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ราคาหลังหักส่วนลด</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>
                {{ total_price | numeral('0,0.00')}}
                </span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6 ">
            <v-col cols="4" class="text-left">
              <div>ภาษีมูลค่าเพิ่ม 7%</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ vat_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-h6">
            <v-col cols="4" class=" text-left">
              <v-checkbox
                class="text-h6 mt-0"
                v-model="form.is_withholding"
              >
                <template v-slot:label>
                  <span style="font-size: 18px">หัก ณ ที่จ่าย 3%</span>
                </template>
              </v-checkbox>
              <!-- <div align="left" class="mb-3">หัก ณ ที่จ่าย 3%</div> -->
            </v-col>
            <v-col cols="2" class=" text-right">
              <span>{{ withholding | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-row justify="center" class="text-h6 red--text">
            <v-col cols="4" class="text-left">
              <div class="mb-3">จำนวนเงินรวมทั้งสิ้น</div>
            </v-col>
            <v-col cols="2" class="text-right">
              <span>{{ net_price | numeral('0,0.00') }}</span>
            </v-col>
          </v-row>

          <v-row class="py-2">
            <v-col  class="text-h5 text-center">
              <div>กรุณาโอนเงินเข้าบัญชี <span class="info--text">ธนาคารกรุงไทย</span></div>
              <div class="pt-3">ชื่อบัญชี <span class="info--text">บริษัท ยักษ์ใหญ่ ซอฟต์ จำกัด</span> เลขที่ <span class="info--text">017-0-25012-1</span></div>
              <div class="pt-3">ส่งสลิป แจ้งการชำระเงินได้ที่ Line id: <span class="success--text">@P2277</span></div>
            </v-col>
          </v-row>

          <!-- <v-row justify="space-around">
            <v-col cols="12 mb-5" align="center">
              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 1 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 1"
              >
                <v-icon left>mdi-qrcode-scan</v-icon>โอนผ่าน QR CODE
              </v-btn>

              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 2 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 2"
              >
                <v-icon left>mdi-credit-card</v-icon>ชำระผ่านบัตรเครดิต
              </v-btn>

              <v-btn
                class="mr-2 mb-5"
                :color="form.payment_type == 3 ? 'primary' : 'grey'"
                outlined
                @click="form.payment_type = 3"
              >
                โอนผ่านเลขบัญชี
              </v-btn>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn color="success" class="px-5 mr-10" href="https://lin.ee/Y6vAGUG" target="_blank">
            แจ้งการชำระเงิน
          </v-btn>
          <!-- <v-btn outlined class="px-5 mr-10" color="red" @click="confirm()">
            ยืนยันการชำระเงิน
          </v-btn> -->
          <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog persistent scrollable max-width="600" v-model="dialogSuccess">
      <Success :item_id="bill_id" @onClose="dialogSuccess = false"></Success>
    </v-dialog>
  </v-row>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import Success from '@/views/pages/package/components/Success.vue';
import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import * as moment from 'moment';
import { required, numeric, minValue } from 'vuelidate/lib/validators';

export default {
  components: {
    Success,
    PrintDocument,
    DatePicker,
    TimePicker
  },

  data: () => ({
    dialogSuccess: false,
    isActivePay: true,
    bill_id: '',
    form: {
      product_id: '',
      coupon_code: '',
      net_price: 0,
      is_withholding: false,
      payment_type: 0
    },
    data: {
      customer_name: '',
      car_plate: '',
      car_province: ''
    },
  }),

  async created() {
    this.form.product_id = this.$route.params.id;
    await this.getData();
  },
  methods: {
    close() {
      this.$router.push({name: 'package-your'});
    },
    async confirm() {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            this.form.net_price = this.net_price
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {noTimestamp: true})
            };
            await this.$axios.post(`${this.$baseUrl}/package/pay_bill`, body)
              .then(res => {
                console.log('success');
                this.bill_id = res.result;
                this.dialogSuccess = true;
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      let body = {
        token: this.$jwt.sign({_id: this.$route.params.id}, this.$privateKey, {noTimestamp: true})
      };
      await this.$axios.post(`${this.$baseUrl}/backend/product/get_by_id`, body)
        .then(async res => {
          this.data = { ...res.result };
          console.log(this.data)
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  },
  computed: {
    total_price() {
      let price = 0;
      price = this.data.price - this.data.discount
      return price;
    },
    vat_price() {
      let price = 0;
      price = this.data.vat==1 ? this.total_price*7/100 : 0
      return price;
    },
    withholding() {
      let price = 0;
      price = this.form.is_withholding ? this.total_price*3/100 : 0
      return price;
    },
    net_price() {
      let price = 0;
      price += this.total_price
      price += this.vat_price
      price -= this.withholding
      return price;
    },
  },
};
</script>
